<template>
  <div class="xfooter">
    <ul class="footer_cen">
      <li @click="microBlog">
        <img class="xingzhe" src="../../assets/images/footer/BBS.png" alt="">
        <p>留言板</p>
      </li>
      <li>
        <ul>
          <li>公司名称：北京蓝灯鱼智能科技有限公司</li>
          <li>联系电话：010-5845 7888</li>
          <li>客服邮箱：service@lanternfish.cn</li>
          <li>公司地址：北京市海淀区知春路甲48号盈都大厦3号楼3单元17C</li>
        </ul>
      </li>
      <li>
        <img src="../../assets/images/footer/thePublic.jpg" alt="">
        <p>关注公众号</p>
      </li>
    </ul>
    <ul class="theStatement">
      <li>
        商标申请范围、最新更新时间，<span style="cursor: pointer" @click="supportcountry">详见《支持查询的国家/地区》信息表</span>
      </li>
      <li>
        免责声明：由于数据更新存在时间差，查询结果仅供参考，不具有法律效力。
      </li>
    </ul>
    <div  class="footer_right"><a style="color: rgba( 187, 185, 201, 0.502 )" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备18114618号</a>
      © 2022 Lanternfish. All rights reserved.
    </div>
  </div>
</template>

<script>
  export default {
    name: "xfooter",
    data(){
      return{
      }
    },
    mounted() {
      if(this.$i18n.locale === 'cn'){
        this.isCN = true
      }else {
        this.isCN = false
      }
    },
    methods:{
      //跳到微博
      microBlog(){
        window.open("https://forum.lanternfish.cn");
      },
      //跳转支持国家页面
      supportcountry(){
        this.$router.push('/supportCountry')
      },
    }
  }
</script>

<style lang="less" scoped>
  @keyframes scaleDraw {  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
    0%{
      transform: scale(1);  /*开始为原始大小*/
    }
    25%{
      transform: scale(1.1); /*放大1.1倍*/
    }
    50%{
      transform: scale(1);
    }
    75%{
      transform: scale(1.1);
    }
  }

  .xfooter{
    width: 100%;
    height: 320px;
    background: #08061e;
    position: absolute;
    .footer_cen{
      width: 1006px;
      margin: 50px auto 0;
      display: flex;
      justify-content: space-between;
      img{
        width: 117px;
        height: 116px;
        display: block;
        margin-bottom: 4px;
      }
      .xingzhe{
        cursor: pointer;
        &:hover{
          -webkit-animation-name: scaleDraw; /*关键帧名称*/
          -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
          -webkit-animation-iteration-count: infinite;  /*动画播放的次数*/
          -webkit-animation-duration: 5s; /*动画所花费的时间*/
        }
      }
      p{
        font-size: 12px;
        font-weight: 500;
        color: #9A9898;
        letter-spacing: 1px;
      }
      li{
        text-align: center;
      }
      ul{
        li{
          font-size: 14px;
          font-weight: 500;
          color: #6F6D7C;
          line-height: 30px;
          letter-spacing: 1px;
        }
      }
    }
    .theStatement{
      width: 890px;
      display: flex;
      margin: 18px auto 0;
      font-size: 12px;
      font-weight: 500;
      color: #6F6D7C;
      letter-spacing: 1px;
      li:nth-of-type(1){
        width: 417px;
        position: relative;
        margin-right: 21px;
        &:after{
          width: 1px;
          height: 16px;
          position: absolute;
          right: 0;
          top: 1px;
          content: '';
          background: #999999;
        }
      }
    }
    .footer_right{
      padding-top: 12px;
      text-align: center;
      /*position: absolute;*/
      /*bottom: 38px;*/
      font-size: 12px;
      font-weight: 500;
      color: #6F6D7C;
    }
  }
</style>
