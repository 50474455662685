<template>
  <div class="order_wrap">
    <div class="order_con">
      <div class="order_info">
        <h6>订单信息</h6>
        <div class="order_list">
          <div class="order_list_item">
            <div class="order_detail_con">
              <div class="maturity">
                <div v-if="this.$route.query.type && this.$route.query.type == 'tradmark'">
                  <ul class="maturity_title">
                    <li>
                      <span>商标名称</span>
                      <span>申请号</span>
                      <span>类别</span>
                      <span>提交方式</span>
                      <span>续展官费</span>
                      <span>宽展费</span>
                      <span>代理费</span>
                      <span>续展官费总计</span>
                    </li>
                  </ul>
                  <ul >
                    <li :key="index" v-for="(item,index) in detailData.paymentOrderList">
                      <el-tooltip class="item" effect="dark" :content="item.trademarkName" placement="top">
                        <span>{{item.trademarkName}}</span>
                      </el-tooltip>

                      <span>{{item.trademarkApplyNo}}</span>
                      <span>{{item.renewalNiceCode}}</span>
                      <span>{{item.submitType == 0 ? '电子' : '纸质'}}</span>
                      <span>{{item.renewalAmount}}</span>
                      <span>{{item.extendedAmount}}</span>
                      <span>{{item.agencyAmount}}</span>
                      <span>{{item.renewalSummaryAmount}}</span>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <ul class="maturity_title">
                  <li>
                    <span style="width: 25%;margin-right: 15px">专利名称</span>
                    <span style="width: 20%;margin-right: 15px">专利号</span>
                    <span>专利年度</span>
                    <span>专利年费</span>
                    <span>滞纳金</span>
                    <span>待权利恢复费</span>
                    <span>代理费</span>
                    <span>年费减免</span>
                    <span>年费总计</span>
                  </li>
                </ul>
                  <ul >
                    <li :key="index" v-for="(item,index) in detailData.paymentOrderList">
                      <el-tooltip class="item" effect="dark" :content="item.patentName" placement="top">
                        <span style="width: 25%;margin-right: 15px">{{item.patentName}}</span>
                      </el-tooltip>

                      <span style="width: 20%;margin-right: 15px">{{item.patentApplyNo}}</span>
                      <span>{{item.patentYear}}</span>
                      <span>{{getPatentBasicFee(item)}}</span>
                      <span>{{item.annualLateFee}}</span>
                      <span>{{item.patentRestorationFee}}</span>
                      <span>{{item.agentFee}}</span>
                      <span>{{item.annualFeeReduction}}%</span>
                      <span>{{item.annualFeeTotal}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6 v-if="$route.query.type == 'tradmark'">续展信息</h6>
        <div class="ruleForm">
          <el-form  label-position="left" v-if="$route.query.type == 'tradmark'" :rules="rules" ref="ruleForm"  label-width="150px" :inline="true" :model="ruleForm" class="demo-form-inline">
            <el-form-item  label="申请人类型：" prop="resource">
              <el-radio-group @change="applicantType"  v-model="ruleForm.applicantType">
                <el-radio label="enterprise">企业</el-radio>
                <el-radio label="individual">个体工商户</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item disabled label="申请人名称（中文）：">
              <el-input disabled  v-model="ruleForm.applicantName" placeholder="输入申请人名称（中文）"></el-input>
            </el-form-item>
            <el-form-item disabled label="申请人地址：">
              <el-input disabled v-model="ruleForm.applicantAddress" placeholder="输入申请人地址"></el-input>
            </el-form-item>
            <el-form-item label="联系人名称：">
              <el-input v-model="ruleForm.contactPersonName" placeholder="输入联系人名称"></el-input>
            </el-form-item>
            <el-form-item label="联系人电话：">
              <el-input v-model="ruleForm.contactPersonPhone" placeholder="输入联系人电话"></el-input>
            </el-form-item>
            <el-form-item  label="">
              <div slot="label">
                <span v-if="ruleForm.applicantType == 'enterprise'" >统一社会信用代码：</span>
                <el-select v-else v-model="ruleForm.idType" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <el-input v-model="ruleForm.idCode" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="联系人邮编：">
              <el-input v-model="ruleForm.contactPostCode" placeholder="输入联系人邮编"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <h6>支付方式:</h6>
        <div class="pay_list">
          <p v-for="(item,index) in payMethod" :key="index" :class="{'active':item.chooseBool}"
             @click="payMethodEvent(item)">
                <span class="triangle">
                  <i style="color: white" class="el-icon-check"></i>
                </span>
            <b><img :src="item.url" alt=""></b>
            <span>{{item.name}}</span>
          </p>
        </div>
      </div>

      <div :class="['paymentAmount',{active: $route.query.type == 'patent'}]">
        <div >
          <div class="paymentAmount_content">
            <img src="../../assets/images/hostingPLatform/zhifu.png" alt="">
            <p style="padding-left: 15px" class="amount">支付金额: <span style="margin-left: 15px">{{totalPric}}元</span>&nbsp;</p>
          </div>
        </div>
        <el-button :class="['el-order-btn',{'disabled':disabledBool}]" @click="payEvents">立即支付</el-button>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="account_dialog"
               :before-close="closeEvent">
      <info @dialog="getDialog" :dialogVisible='dialogVisible' :server-type="0" :dialog-close="dialogClose"
            @dialogCloseBool="dialogCloseBool"></info>
    </el-dialog>
  </div>
</template>
<script>
  import { payZfb, renewalPayZfb } from "@/request/trademarkPayment";
  import zfb from '@/assets/images/common/zfb.jpg'
  import gong from '@/assets/images/common/gong.jpg'
  import wx from '@/assets/images/common/wxs.jpg'
import xfooter from '@/components/common/xfooter'
import info from "@/components/account/info"
export default {
  name: "orderInfo",
  data () {
    return {
      businessCustomer: {},
      rules: {
        applicantName: [
          { required: true, message: '输入内容不得为空', trigger: 'blur' }
        ],
        applicantAddress: [
          { required: true, message: '输入内容不得为空', trigger: 'blur' }
        ],
        contactPersonName: [
          { required: true, message: '输入内容不得为空', trigger: 'blur' }
        ],
        contactPersonPhone: [
          { required: true, message: '输入内容不得为空', trigger: 'blur' }
        ],
        idCode: [
          { required: true, message: '输入内容不得为空', trigger: 'blur' }
        ],
        contactPostCode: [
          { required: true, message: '输入内容不得为空', trigger: 'blur' }
        ]
      },
      ruleForm: {
        //订单号
        orderNumber: '',
        //申请人类型
        applicantType: 'enterprise',
        //申请人名称
        applicantName: '',
        //申请人地址
        applicantAddress: '',
        //联系人名称
        contactPersonName: '',
        //联系人地址
        contactPersonAddress: '',
        //联系人邮编
        contactPostCode: '',
        //联系人电话
        contactPersonPhone: '',
        //证件类型：1统一信用代码，2身份证，3护照，4其它
        idType: '1',
        // 证件号码
        idCode: '',
        customerCategory: 'trademark'
  },
      options: [{
        value: '2',
        label: '身份证'
      }, {
        value: '3',
        label: '护照'
      }, {
        value: '4',
        label: '其它'
      }],
      number: '',
      //总价格
      totalPric: '',
      //是否有订单
      orderFlag: false,
      //支付类型
      payType: 'WX',
      payMethod: [
        {
          name: "微信支付",
          url: wx,
          chooseBool: true,
          type: "WX"
        },
        {
          name: "支付宝支付",
          url: zfb,
          chooseBool: false,
          type: "ZFB"
        },
        // {
        //   name: "对公支付",
        //   url: gong,
        //   chooseBool: false,
        //   type: "gong"
        // },
      ],
      detailData: {},
      detailDatas: {},
      detailDatass: {},
      disabledBool: false,
      dialogVisible: false,
      dialogClose: false,
      ids:[]
    }
  },
    mounted () {
      //----------------------------------------------------------
      this.init();
      this.getContactLanAjax();
      if(this.$route.query.type == 'tradmark'){

      }
    },
    components: { info, xfooter },
    methods: {
      getPatentBasicFee(row){
        let patentType = ""
        if (row.annuityName.startsWith("发明")){
          patentType = 0
        }
        else if(row.annuityName.startsWith("实用新型")){
          patentType = 1
        }
        else {
          patentType = 2
        }
        switch (patentType){
          case 0:
            //发明专利
            switch (row.patentYear){
              case 1:
              case 2:
              case 3:
                return 900
              case 4:
              case 5:
              case 6:
                return 1200
              case 7:
              case 8:
              case 9:
                return 2000
              case 10:
              case 11:
              case 12:
                return 4000
              case 13:
              case 14:
              case 15:
                return 6000
              case 16:
              case 17:
              case 18:
              case 19:
              case 20:
                return 8000
              default:
                return ""
            }
          case 1:
          case 2:
            //实用新型专利、外观设计专利
            switch (row.patentYear){
              case 1:
              case 2:
              case 3:
                return 600
              case 4:
              case 5:
                return 900
              case 6:
              case 7:
              case 8:
                return 1200
              case 9:
              case 10:
                return 2000
              default:
                return ""
            }
          default:
            return ""
        }
      },
      applicantType(e){
        if(e == 'enterprise'){
          this.ruleForm.idType = '1'
        }else {
          this.ruleForm.idType = '2'
        }
      },
      queryDataUrl(data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      },
      payLater(data) {
        if (data.payUrl.includes("paySuccess")) {
          window.location.href = data.payUrl;
        } else {
          const tradePage = data;
          const div = document.createElement('div'); // 创建div
          div.innerHTML = tradePage.payUrl; // 将返回的form 放入div
          document.body.appendChild(div);
          document.forms[0].submit();
          document.body.removeChild(div);
        }
      },
      payLaterRenewal(data) {
        if (data.displayMode == "url") {
          window.location.href = data.displayContent;
        } else {
          const tradePage = data;
          const div = document.createElement('div'); // 创建div
          div.innerHTML = tradePage.displayContent; // 将返回的form 放入div
          document.body.appendChild(div);
          document.forms[0].submit();
          document.body.removeChild(div);
        }
      },
      // 续展支付
      payWay4(orderId) {
        // if(window.location.origin=='http://www.youxuanzifu.com'||window.location.origin=='http://www.premiword.com.cn'){
        renewalPayZfb({
          id: orderId,
          channelCode: 'alipay_pc',
          returnUrl: this.$route.query.type == 'tradmark' ? window.location.origin + '/#/hostingPlatforms/informationupload' : window.location.origin + '/#/hostingPlatforms/myAnnualFee?type=pay'
        })
          .then(({msg, code, data}) => {
            if (code == 0){
              this.payLaterRenewal(data);
            }
          })
          .catch((error) => {
            //   this.$message("系统未知错误,请反馈给管理员");
            console.log(error);
          })
      },
      //支付宝支付
      payWay3(orderId) {
        // if(window.location.origin=='http://www.youxuanzifu.com'||window.location.origin=='http://www.premiword.com.cn'){
        payZfb({
          channelId: "ALIPAY_PC",
          orderId: orderId,
          paymentType: 'PAY_ALL',
          basePath: window.location.origin
        })
          .then(({msg, code, data}) => {
            if (code) return;
            data = JSON.parse(data);
            this.payLater(data);
          })
          .catch((error) => {
            //   this.$message("系统未知错误,请反馈给管理员");
            console.log(error);
          })
      },

      //   初始化
      async init () {
        if(this.$route.query.type == 'tradmark') {
          localStorage.setItem('type', 1)
          let data = await this.$axios.post(`/custody/renewal/order/detail`,{
            renewalOrderNumber: this.$route.query.renewalOrderNumber
          });
          if (data.data.code == 0) {
            data.data.data.paymentOrderList = data.data.data.renewalOrderList;
            this.detailData = data.data.data;
            this.totalPric = data.data.data.orderSummaryAmount
            this.businessCustomer =  data.data.data.businessCustomer;
            this.ruleForm.applicantName = this.businessCustomer.applicantName
            this.ruleForm.applicantAddress = this.businessCustomer.applicantAddress
            this.ruleForm.orderNumber = data.data.data.renewalOrderNumber
          }
        }else {
          localStorage.setItem('type', 2)
          let {data} = await this.$axios.get(`/custody/annuity/order/detail/${this.$route.query.renewalOrderNumber}`);

          if (data.code == 0) {
            data.data.paymentOrderList = data.data.annuityDetailDtoList;
            this.detailData = data.data;
            this.totalPric = data.data.orderSummaryAmount
          }
        }

      },
      payMethodEvent (data) {
        let datas = []
        if (data.chooseBool) {
          return;
        }
        this.payMethod.forEach((item, index) => {
          item.chooseBool = false;
        })
        data.chooseBool = !data.chooseBool;
        this.payType = data.type
        datas = this.payMethod.filter(item => {
          return item.name == '对公支付'
        })
        if(datas[0].chooseBool == true){
          this.corporateFlag = true
        }else {
          this.corporateFlag = false
        }
      },
      payEvents () {
        if(this.$route.query.type == 'tradmark'){
          let flag = false
          this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
              flag = true
            } else {
              flag = false
            }
          });

          if(!flag) {
            this.$message({
              message: '订单信息填写不完整',
              type: 'warning'
            });
            return;
          };
          this.$axios
            .post("/custody/business/customer/create",this.ruleForm).then(({data}) => {
            localStorage.setItem('ruleForm',JSON.stringify(this.ruleForm))
            this.payEvent()
          }).catch(err => {
            this.$message({
              message: '订单信息填写不完整',
              type: 'warning'
            });
          })
        }else {
          this.payEvent()
        }

      },
      payEvent () {

        //对公支付
        if(this.corporateFlag){
          this.$router.push({
            path: '/payment',
            query: {
              orderId: this.$route.query.type == 'tradmark' ? this.detailData.renewalOrderNumber : this.detailData.annuityOrderNumber,
              name: this.$route.query.type == 'tradmark' ? '续展订单' : '专利订单',
              price: this.totalPric,
              tradmark: this.$route.query.type ? this.$route.query.type : undefined,
              id: this.detailData.id,
              number: this.$route.query.renewalOrderNumber
            }
          })
          return ;
        }

        if(this.$route.query.type == 'tradmark'){
          this.$axios
            .post("/custody/renewal/order/create/platform/order/number",{
              renewalOrderNumber: this.detailData.renewalOrderNumber,
              orderPaymentType: this.payType == 'ZFB' ? 1 : 2
            })
            .then(({data}) => {
              if (data.code == 0) {
                //支付宝
                if(this.payType == 'ZFB'){
                  this.payWay4(data.data)
                  return;
                }
                this.$router.push({
                  path: "/pay/pay",
                  query: {
                    orderId: data.data,
                    price: this.totalPric,
                    flag: this.$route.query.flag ? this.$route.query.flag : undefined,
                    tradmark: this.$route.query.type ? this.$route.query.type : undefined,
                    renewalOrderNumber: this.detailData.renewalOrderNumber ? this.detailData.renewalOrderNumber : undefined,
                  }
                })        //对公支付
              }
            })
        }else {
          if(this.payType == 'ZFB'){
            this.payWay4(this.$route.query.renewalOrderNumber)
            return;
          }
          this.$router.push({
            path: "/pay/pay",
            query: {
              orderId: this.$route.query.renewalOrderNumber,
              price: this.totalPric,
              flag: this.$route.query.flag ? this.$route.query.flag : undefined,
              tradmark: this.$route.query.type ? this.$route.query.type : undefined,
              renewalOrderNumber: this.detailData.annuityOrderNumber ? this.detailData.annuityOrderNumber : undefined,
            }
          })
        }


      },
      // 生成订单
      async saveOrderAjax () {
        let obj = []
        if(this.ids.length == 2){
          obj = [
            {
              productServiceId: this.detailData.id,
              productId: this.detailData.productId,
              price: this.detailData.price,
              currency: this.detailData.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern', //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
              quantity:this.$route.query.flag ? this.$route.query.monitorFlag : (this.$route.query.monitorFlag ? this.$route.query.monitorFlag/10 : undefined)
            },{
              productServiceId: this.detailDatas.id,
              productId: this.detailDatas.productId,
              price: this.detailDatas.price,
              currency: this.detailDatas.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern' //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
            }
          ]
        }else if(this.ids.length == 3){
          obj = [
            {
              productServiceId: this.detailData.id,
              productId: this.detailData.productId,
              price: this.detailData.price,
              currency: this.detailData.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern', //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
              quantity:this.$route.query.flag ? this.$route.query.monitorFlag : (this.$route.query.monitorFlag ? this.$route.query.monitorFlag/10 : undefined)
            },{
              productServiceId: this.detailDatas.id,
              productId: this.detailDatas.productId,
              price: this.detailDatas.price,
              currency: this.detailDatas.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern' //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
            },{
              productServiceId: this.detailDatass.id,
              productId: this.detailDatass.productId,
              price: this.detailDatass.price,
              currency: this.detailDatass.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern' //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
            }
          ]
        } else {
          obj  = [
            {
              productServiceId: this.detailData.id,
              productId: this.detailData.productId,
              price: this.detailData.price,
              currency: this.detailData.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern', //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
              quantity:this.$route.query.flag ? this.$route.query.monitorFlag : (this.$route.query.monitorFlag ? this.$route.query.monitorFlag/10 : undefined)
            }
          ]
        }

        if(this.$route.query.flag && this.$route.query.flag == 2){
          localStorage.setItem('orderId',this.$route.query.ids)
          localStorage.setItem('MT',1)
          //对公支付
          if(this.corporateFlag){
            this.$router.push({
              path: '/payment',
              query:{
                orderId:this.$route.query.orderNum,
                name: this.detailData.serviceName,
                price: this.totalPric
              }
            })
            return ;
          }

          if(this.payType == 'ZFB'){
            this.payWay3(this.$route.query.ids)
            return;
          }
          this.$router.push({
            path: "/pay/pay",
            query: {
              orderId: this.$route.query.ids,
              price: this.totalPric,
              flag: this.$route.query.flag ? this.$route.query.flag : undefined
            }
          })
          return;
        }
        let data = await this.$axios.post("/order/order/createLanternfishOrder", obj);
        if (data.data.code == 0) {
          localStorage.setItem('orderId',data.data.data.id)
          if(this.$route.query.flag){
            localStorage.setItem('MT',1)
          }else {
            localStorage.removeItem('MT')
          }
          //对公支付
          if(this.corporateFlag){
            this.$router.push({
              path: '/payment',
              query:{
                orderId:data.data.data.orderNum,
                name: this.detailData.serviceName,
                price: this.totalPric
              }
            })
            return ;
          }

          if(this.payType == 'ZFB'){
            this.payWay3(data.data.data.id)
            return;
          }
          this.$router.push({
            path: "/pay/pay",
            query: {
              orderId: data.data.data.id,
              price: this.totalPric,
              flag: this.$route.query.flag ? this.$route.query.flag : undefined
            }
          })
        }
      },
      infoEvent () {
        this.dialogVisible = true;
      },
      getDialog (param) {
        this.dialogVisible = param;
        this.getContactLanAjax();
      },
      //  获取联系人信息
      async getContactLanAjax () {
        let data = await this.$axios.get("/admin/contact/getContact");
        if (data.data.msg == "success") {
          if (data.data.data != null) {
            this.disabledBool = false;
          } else {
            this.disabledBool = true;
          }
        }
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
    }
  }
</script>
<style lang="less" scoped>
  .order_wrap {
    height: 805px;
    overflow-y: auto;
    overflow-x: hidden;
    /*padding: 74px 0px 0px;*/
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);
    border-radius: 15px 15px 15px 15px;
    position: relative;
    .order_con {
      width: 100%;
      height: 805px;
      box-sizing: border-box;
      .order_info {
        padding: 13px 19px;
        .ruleForm {
          padding: 0 15px 30px;
          box-sizing: border-box;
          .demo-form-inline {
            padding: 20px 0 10px 30px;
            box-sizing: border-box;
            width: 100%;
            background: #EFF1F5;
            border-radius: 8px;
            .el-form-item:nth-of-type(1){
              width: 100%;
            }
            /deep/.el-select {
              text-align: right;
              .el-input__inner {
                background: #EFF1F5;
                border: none;
                padding-left: 0px;
              }
            }
            .el-form-item {
              margin-right: 40px;
              margin-bottom: 10px;
            }
          }
        }

        h6 {
          font-size: 16px;
          color: #424242;
          position: relative;
          padding-left: 12px;
          margin-bottom: 20px;
          &::before {
            content: "";
            width: 4px;
            height: 22px;
            background-color: @blue;
            position: absolute;
            top: 2px;
            left: 0;
          }
        }
        .pay_list{
          display: flex;
          margin-left: 33px;
          p{
            overflow: hidden;
            width: 156px;
            height: 45px;
            border-radius: 8px;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            margin-bottom: 22px;
            background: #FFFFFF;
            border: 1px solid #9E9E9E;
            margin-right: 30px;
            cursor: pointer;
            .triangle{
              display: none;
            }
            b {
              display: inline-block;
              width: 20px;
              vertical-align: -6px;
              img {
                display: block;
                width: 100%;
              }
            }
            span {
              color: #606060;
              font-size: 14px;
              margin-left: 6px;
            }
            &.active {
              border: 1px solid #407FF3;
              .triangle {
                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0;
                height: 0;
                border-bottom: 26px solid #407FF3;
                border-left: 26px solid transparent;
                i{
                  position: absolute;
                  bottom: -25px;
                  right: 0;
                }
              }
            }
          }
        }
        .order_list {
          padding-left: 15px;
          .demo-form-inline {
            padding-left: 100px;
          }
          .order_list_item {
            line-height: 55px;
            color: #595a5e;
            font-size: 14px;
            label {
              margin-right: 40px;
            }
            .red {
              color: #eb5657;
            }
            p {
              display: inline-block;
              height: 33px;
              line-height: 33px;
              padding: 0 15px;
              border: solid 1px #cccccc;
              position: relative;
              cursor: pointer;
              margin-right: 20px;
              b {
                display: inline-block;
                width: 20px;
                vertical-align: -6px;
                img {
                  display: block;
                  width: 100%;
                }
              }
              span {
                color: #333;
                font-size: 14px;
                margin-left: 6px;
              }
              &.active {
                border: solid 1px @blue;
              }
            }
            .order_detail_con {
              width: 100%;
              display: inline-block;
              margin-bottom: 25px;
              .maturity {
                padding: 14px 21px;
                box-sizing: border-box;
                background: #E4EDFF;
                border-radius: 5px 5px 5px 5px;
                .maturity_title {
                  width: calc(100% - 10px);
                  margin-bottom: 11px;
                  li {
                    display: flex;
                    padding: 0px 10px;
                    box-sizing: border-box;
                    span {
                      text-align: left;
                      display: block;
                      width: 10%;
                      font-weight: bold;
                      font-size: 14px;
                      color: #2D3748;
                      &:nth-of-type(1) {
                        width: 20%;
                      }
                      &:nth-last-of-type(1) {
                        width: 10%;
                      }
                    }
                  }
                }
                ul:nth-of-type(2) {
                  width: 100%;
                  max-height: 160px;
                  margin-bottom: 10px;
                  background: #E4EDFF;
                  overflow-y: auto;
                  //result-ico
                  // 滚动条的宽度
                  &::-webkit-scrollbar {
                    width: 10px; // 横向滚动条
                    height: 10px; // 纵向滚动条 必写
                  }

                  // 滚动条的滑块
                  &::-webkit-scrollbar-thumb {
                    background: hsla(0, 0%, 53%, .1);
                    border-radius: 10px
                  }

                  &::-webkit-scrollbar-track {
                    background: hsla(0, 0%, 53%, .1)
                  }
                  li {
                    padding: 24px 11px;
                    box-sizing: border-box;
                    height: 40px;
                    background: #FFFFFF;
                    box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.08);
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 11px;
                    span {
                      text-align: left;
                      display: block;
                      align-items: center;
                      white-space: nowrap;        /* 禁止换行 */
                      overflow: hidden;           /* 隐藏溢出内容 */
                      text-overflow: ellipsis;    /* 显示省略号 */
                      font-weight: 500;
                      font-size: 14px;
                      color: #434E5F;
                      width: 10%;
                      &:nth-of-type(1) {
                        padding-right: 5px;
                        box-sizing: border-box;
                        width: 20%;
                      }
                      &:nth-last-of-type(1) {
                        width: 10%;
                      }
                      &.status {
                        i.you {
                          width: 53px;
                          height: 20px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          font-weight: 400;
                          font-size: 12px;
                          color: #4F5AED;
                          background: #EAECFF;
                          border-radius: 30px;
                        }
                        i.err {
                          width: 53px;
                          height: 20px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          font-weight: 400;
                          font-size: 12px;
                          color: #D12953;
                          background: #FAF0F3;
                          border-radius: 30px;
                        }
                      }
                    }
                  }
                }

              }
              .order_detail {
                background: #E4EDFF;
                border-radius: 8px!important;
                padding: 0 15px 30px;
                .el-table  {
                  /deep/.el-table__header{
                    th{
                      background: #E4EDFF;
                      padding: 0!important;
                      font-weight: bold;
                      font-size: 14px;
                      color: #2D3748;
                    }
                  }
                  /deep/.el-table__empty-block {
                    background: #E4EDFF!important;
                  }
                  /deep/.el-table__row {
                    height: 45px;
                    background: #FFFFFF;
                    box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.08);
                    border-radius: 8px;
                    margin-bottom: 12px;
                    font-weight: 500;
                    font-size: 14px;
                    color: #434E5F;
                  }
                }
                display: flex;
                width: 420px;
                border-radius: 3px;
                /*border: solid 1px #ecf0f4;*/
                dl {
                  display: inline-block;
                  text-align: center;
                  flex: 1;
                  dt {
                    height: 37px;
                    background: #E2EFFF;
                    border-bottom: 1px solid #eff2f5;
                    line-height: 37px;
                    color: #616267;
                  }
                  dd {
                    /*width: 150px;*/
                    width: 100%;
                    line-height: normal;
                    padding: 10px 0;
                    border-top: 1px solid #B5BECA;
                    span {
                      color: @blue;
                    }
                  }
                }
                &.active{
                  dd {
                    display: flex;
                    padding: 0;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                  }
                }
              }
            }
          }
        }
      }
      .order_list_items{
        line-height: 55px;
        color: #595a5e;
        font-size: 14px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-top: 36px;
        position: relative;
        padding: 28px 27px;
        box-sizing: border-box;
        height: calc(100vh - 500px);

        h6 {
          line-height: 1;
          color: #595a5e;
          font-size: 16px;
          position: relative;
          padding-left: 15px;
          margin-bottom: 30px;
          &::before {
            content: "";
            width: 4px;
            height: 22px;
            background-color: @blue;
            position: absolute;
            left: 0;
            top: -4px;
          }
        }
        .red {
          color: #eb5657;
        }
      }
      .order_hint {
        font-size: 14px;
        font-weight: 400;
        color: #606060;
        margin-top: 10px;
        line-height: 21px;
        letter-spacing: 1px;
      }
      .paymentAmount{
        &.active {
          position: absolute!important;
          bottom: 0;
        }
        position: relative;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        &:before {
          padding: 15px 0 0 66px;
          box-sizing: border-box;
          top: -2px;
          left: 0px;
          content: '本费用为网站初始计算得出，实际应该费用待人工核查后，与您联系多退少补';
          position: absolute;
          width: 100%;
          display: block;
          height: 50px;
          background: url("../../assets/images/hostingPLatform/jux.png") no-repeat;
          background-size: 100% 100%;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 20px;
          letter-spacing: 1px;
          text-shadow: 0px 2px 4px rgba(0,0,0,0.37);
        }
        padding: 53px 104px;
        box-sizing: border-box;
        height: 206px;
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.22);
        width: 100%;
        .amount{
          font-size: 22px;
          font-weight: 500;
          color: #535353;
          line-height: 33px;
          letter-spacing: 1px;
          span{
            font-size: 45px;
            font-weight: bold;
            color: #FF2625;
            line-height: 68px;
            letter-spacing: 3px;
          }
        }
        .zhe{
          margin-right: 3px;
          display: table-cell;
          width: 40px;
          height: 20px;
          background: #ff6a37;
          color: white;
          border-radius: 5px;
          font-size: 14px;
          vertical-align:middle;
          text-align:center;
          margin-left: 8px;
        }
        .el-order-btn {
          width: 128px;
          height: 45px;
          background: #215CC2;
          border-radius: 8px;
          margin-top: 45px;
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
          &:hover {
            box-shadow: @boxShadow;
          }
          &.disabled {
            background-color: #d9d9dc;
            cursor: default;
            &:hover {
              box-shadow: none;
            }
          }
        }
        .paymentAmount_content{
          margin-top: 20px;
          display: flex;
          align-items: baseline;
          p {
            font-weight: 500;
            font-size: 22px;
            color: #535353;
            span {
              font-weight: 600;
              font-size: 45px;
              color: #D33030;
              letter-spacing: 2px;
            }
          }
        }
      }
    }
  }
</style>
<style lang="less">
  .wxCode_dialog {
    .el-dialog__header {
      display: none;
    }
    #qrcode {
      width: 220px;
      height: 220px;
      margin: 0 auto;
    }
  }
</style>
<style lang="less">
  .account_dialog {
    width: 600px;
    height: 740px;
    background-color: #ffffff;
    box-shadow: 0px 6px 48px 0px rgba(128, 128, 128, 0.16);
    border-radius: 5px;
    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
    .info_wrap {
      .info_con {
        padding: 45px 80px 80px;
      }
    }
  }
</style>
